import React, { useState } from 'react';
import './QuizMain.css';
import Calculator from './Calculator';
import { Link } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect } from 'react';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';

import { getAuth, signOut } from "firebase/auth";



import { auth, db } from '../firebase'; 
import { collection, addDoc } from 'firebase/firestore';
import { doc, getDoc, setDoc } from 'firebase/firestore';









function QuizMain() {
  const [operation, setOperation] = useState('');
  const [numQuestions, setNumQuestions] = useState(0);
  const [selectedButtons, setSelectedButtons] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [userInput, setUserInput] = useState('');
  const [correctCount, setCorrectCount] = useState(0);
  const [incorrectCount, setIncorrectCount] = useState(0);
  const [quizStarted, setQuizStarted] = useState(false);
  const [userAnswers, setUserAnswers] = useState([]);
  
  const [showResults, setShowResults] = useState(false);


  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  


const func=()=>{
  const auth = getAuth();
  signOut(auth).then(() => {
    // Sign-out successful.
  }).catch((error) => {
    // An error happened.
  });
}






  const submitQuizResults = async () => {
    // The document ID is the user's UID
    const docRef = doc(db, "quizResults", auth.currentUser.uid);
    
    
  
    // Get the existing document
   // Get the existing document
const docSnap = await getDoc(docRef);

// Initialize with default values
let existingData = {
  userEmail: auth.currentUser.email,
  correctAnswers: 0,
  incorrectAnswers: 0,
  timeTaken: 0,
  teacherEmail: '',
  studentName:'',
  class:'',
};

// If the document exists, get its data
if (docSnap.exists()) {
  const data = docSnap.data();
  existingData = {
    userEmail: data.userEmail || existingData.userEmail,
    correctAnswers: Number.isFinite(data.correctAnswers) ? data.correctAnswers : existingData.correctAnswers,
    incorrectAnswers: Number.isFinite(data.incorrectAnswers) ? data.incorrectAnswers : existingData.incorrectAnswers,
    timeTaken: Number.isFinite(data.timeTaken) ? data.timeTaken : existingData.timeTaken,
    teacherEmail: data.teacherEmail || existingData.teacherEmail,
    studentName: data.studentName || existingData.studentName,
    class: data.class || existingData.class,
  };
}
 


// The new data you want to add
const newData = {
  userEmail: auth.currentUser.email,
  studentName:existingData.studentName,
  class:existingData.class,
  teacherEmail: existingData.teacherEmail,
  correctAnswers: existingData.correctAnswers + correctCount,
  incorrectAnswers: existingData.incorrectAnswers + incorrectCount,
  //timeTaken: parseInt(existingData.timeTaken) + parseInt(((endTime - startTime) / 1000).toFixed(2)),
  timeTaken: existingData.timeTaken + (((endTime - startTime) / 1000))
};



const wquizDocRef = doc(db, "wquizResults", auth.currentUser.uid);

  let existingWquizData = {

    userEmail: auth.currentUser.email,
    correctAnswers: 0,
    incorrectAnswers: 0,
    timeTaken: 0,
    teacherEmail: existingData.teacherEmail, // take from existingData
  studentName: existingData.studentName, // take from existingData
  class: existingData.class,
  };
  const wquizDocSnap = await getDoc(wquizDocRef);

  if (wquizDocSnap.exists()) {
    const wquizData = wquizDocSnap.data();
    existingWquizData = {
      userEmail: wquizData.userEmail || existingWquizData.userEmail,
      correctAnswers: Number.isFinite(wquizData.correctAnswers) ? wquizData.correctAnswers : existingWquizData.correctAnswers,
      incorrectAnswers: Number.isFinite(wquizData.incorrectAnswers) ? wquizData.incorrectAnswers : existingWquizData.incorrectAnswers,
      timeTaken: Number.isFinite(wquizData.timeTaken) ? wquizData.timeTaken :0 ,
      teacherEmail: wquizData.teacherEmail || existingWquizData.teacherEmail,
      studentName: wquizData.studentName || existingWquizData.studentName,
      class:wquizData.class || existingWquizData.class,
    };
  }

  const newWquizData = {
    userEmail: auth.currentUser.email,
    studentName:existingWquizData.studentName,
    class:existingWquizData.class,
    teacherEmail: existingWquizData.teacherEmail,
    correctAnswers: existingWquizData.correctAnswers + correctCount,
    incorrectAnswers: existingWquizData.incorrectAnswers + incorrectCount,
    // timeTaken: ((parseInt(existingWquizData.timeTaken) + parseInt(((endTime - startTime) / 1000)))/60).toFixed(2)
    // timeTaken: parseFloat(((parseInt(existingWquizData.timeTaken) + parseInt(((endTime-startTime) / 1000)))/60).toFixed(2))
     timeTaken: existingWquizData.timeTaken + (((endTime - startTime) / 1000)),

  };


  console.log(newWquizData.timeTaken)



try {
  await setDoc(docRef, newData, { merge: true });
  console.log("Document updated with ID: ", docRef.id);

  await setDoc(wquizDocRef, newWquizData, { merge: true });

} catch (e) {
  console.error("Error updating document: ", e);
}

  };
  
  
  // call the submitQuizResults function when the quiz ends
  useEffect(() => {
    if (endTime !== null) {
      
       
      submitQuizResults();
    }
  }, [endTime]);



  




//   const resetQuiz = () => {
//     setQuestions([]);
//     setCurrentIndex(0);
//     setUserAnswers([]);
//     setCorrectCount(0);
//     setIncorrectCount(0);
//     setUserInput('');
//     setQuizStarted(false);
//     setOperation('');
//     setSelectedButtons([]);
//     setStartTime(null);
//     setEndTime(null);
// };







  useEffect(() => {
    document.documentElement.classList.add('quiz');
    return () => {
      document.documentElement.classList.remove('quiz');
    };
  }, []);


  const generateQuestions = () => {
    if (startTime === null) {
      setStartTime(new Date());
    }
    if (selectedButtons.length === 0) {
      alert("Please select at least one number.");
      return;
    }
  
    const generatedQuestions = [];
  
    if (operation === 'multiplication-alert') {
      const num1 = selectedButtons[Math.floor(Math.random() * selectedButtons.length)];
      const num2 = Math.floor(Math.random() * 10) + 1;
      generatedQuestions.push(`${num1} * ${num2}`);
      
  } 
  else if (operation === 'subtract') {
    // Generate addition questions
    for (let i = 0; i < numQuestions; i++) {
      const num1 = selectedButtons[Math.floor(Math.random() * selectedButtons.length)];
      const num2 = Math.floor(Math.random() * num1) + 1; // Ensures num2 is less than num1
      const question = `${num1} - ${num2}`;
      generatedQuestions.push(question);
      
    }
  }
  
  
  else {
      for (let i = 0; i < numQuestions; i++) {
        const num1 = selectedButtons[Math.floor(Math.random() * selectedButtons.length)];
      const num2 = Math.floor(Math.random() * 10) + 1;
      const question = operation === 'multiplication-alert'
        ? `${num1} * ${num2}`
        : operation === 'both'
          ? Math.random() < 0.5
            ? `${num1} * ${num2}`
            : `${num1 * num2} / ${num1}`
          : operation === 'multiply'
          ? `${num1} * ${num2}`
          : operation === 'divide'
          ? `${num1 * num2} / ${num1}`
          : operation === 'add'
          ? `${num1} + ${num2}`
          : `${num1} - ${num2}`;
      generatedQuestions.push(question);
      
      }
    }

    
  
    setQuestions(generatedQuestions);
    setCurrentIndex(0);
    setQuizStarted(true);
    
  };
  
  
  const submitAnswer = () => {
    // Check if userInput is empty
    if (userInput === '') {
      alert('Please enter an answer.');
      return;
    }
  
    const questionParts = questions[currentIndex].split(' ');
    const correctAnswer =
      questionParts[1] === '*'
        ? parseInt(questionParts[0]) * parseInt(questionParts[2])
        : questionParts[1] === '/'
        ? parseInt(questionParts[0]) / parseInt(questionParts[2])
        : questionParts[1] === '+'
        ? parseInt(questionParts[0]) + parseInt(questionParts[2])
        : parseInt(questionParts[0]) - parseInt(questionParts[2]);
  
    // Update the userAnswers state
    setUserAnswers([...userAnswers, { question: questions[currentIndex], userAnswer: userInput, correctAnswer: correctAnswer }]);
  
    if (parseInt(userInput) === correctAnswer) {
      setCorrectCount((count) => count + 1);
      if (operation !== 'multiplication-alert') {
        setCurrentIndex((index) => {
          const newIndex = index + 1;
          if (newIndex >= questions.length) {
            setEndTime(new Date());
          }
          return newIndex;
        });
      } else {
        generateQuestions(); 
       // setStartTime(new Date()); // Regenerate question on correct answer in 'multiplication-alert' mode
      }
    } else {
      setIncorrectCount((count) => count + 1);
      // if operation is 'multiplication-alert', show results when the answer is incorrect
      if (operation === 'multiplication-alert') {
        setShowResults(true);
         setEndTime(new Date());
      } else {
        // for all other operations, just proceed to the next question
        setCurrentIndex((index) => {
          const newIndex = index + 1;
          if (newIndex >= questions.length) {
            setEndTime(new Date());
          }
          return newIndex;
        });
      }
    }
    
    setUserInput('');
};

  
  


  
  
  
  
  

  
  const handleCalculatorButtonClick = (value) => {
    if (value === 'backspace') {
      setUserInput(userInput.slice(0, -1));
    }
    else if (value === 'submit') {
      submitAnswer();
    }
    
    else {
      if (userInput.length < 9) {
        setUserInput(userInput + value);
      }
    }
  };
  



  useEffect(() => {
    setSelectedButtons(Array.from({ length: 12 }, (_, i) => i + 1));
    setOperation('multiply');
    setNumQuestions(10);
  }, []);

  const deselectAll = () => {
    setSelectedButtons([]);
  };




  const restartQuiz = () => {
  if (operation !== "multiplication-alert") {
    setUserAnswers([]);
    setCorrectCount(0);
    setIncorrectCount(0);
    setCurrentIndex(0);
    setQuestions([]);
    generateQuestions();
  } else {
    setCurrentIndex(0);
    //setQuestions([generateTimelessMultiplicationQuestion()]);
    setUserAnswers([]);
    setCorrectCount(0);
    setIncorrectCount(0);
    setCurrentIndex(0);
    setQuestions([]);
    generateQuestions();
  }
  setUserInput("");
  setShowResults(false);
  setStartTime(new Date());
  setEndTime(null);
};


  

  useEffect(() => {
    const handleKeyDown = (event) => {
      // If the key pressed is a number (0-9)
      if (!isNaN(event.key) && userInput.length < 9) {
        setUserInput(userInput + event.key);
      } 
      // If the key pressed is the backspace key
      else if (event.key === "Backspace") {
        setUserInput(userInput.slice(0, -1));
      }
      // If the key pressed is the enter key and an answer has been entered
      else if (event.key === "Enter" && userInput !== '' && currentIndex < questions.length) {
        submitAnswer();
      }
    }
  
    window.addEventListener('keydown', handleKeyDown);
  
    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    }
  }, [userInput, currentIndex, questions.length]); // Add userInput, currentIndex and questions.length as dependencies to update them when a key is pressed
  
  




  return (
    <div>
    <div className='navbary'>
        <Link to='/main1'><ArrowCircleLeftRoundedIcon className='backarrow'></ArrowCircleLeftRoundedIcon></Link>
       <Link to='/login' > <LogoutIcon className='logout' onClick={func} ></LogoutIcon></Link>

      </div>

    <div className="App">
      <h1>Quiz</h1>
      {!quizStarted ? (
        <>
          <div className='operation'>
            <h2>Choose an operation</h2>
            <button
              onClick={() => setOperation('multiply')}
              style={{ backgroundColor: operation === 'multiply' ? '#4CAF50' : 'skyblue' }}
            >
              Multiplication
            </button>
            <button
              onClick={() => setOperation('divide')}
              style={{ backgroundColor: operation === 'divide' ? '#4CAF50' : 'skyblue' }}
            >
              Division
            </button>
            <button
              onClick={() => setOperation('both')}
              style={{ backgroundColor: operation === 'both' ? '#4CAF50' : 'skyblue' }}
            >
              Both Multiplication and Division
            </button>
            <button
  onClick={() => setOperation('add')}
  style={{ backgroundColor: operation === 'add' ? '#4CAF50' : 'skyblue' }}
>
  Addition 
</button>
<button
  onClick={() => setOperation('subtract')}
  style={{ backgroundColor: operation === 'subtract' ? '#4CAF50' : 'skyblue' }}
>
  Subtraction
</button>
<button
  onClick={() => setOperation('multiplication-alert')}
  style={{ backgroundColor: operation === 'multiplication-alert' ? '#4CAF50' : 'skyblue' }}
>
  Timeless Multiplication
</button>


          </div>



          <div className='operation'>
  
  {operation !== 'multiplication-alert' && (
    <>
    <h2>Choose the number of questions</h2>
      <button
        onClick={() => setNumQuestions(10)}
        style={{ backgroundColor: numQuestions === 10 ? '#4CAF50' : 'skyblue' }}
      >
        10 Questions
      </button>
      <button
        onClick={() => setNumQuestions(20)}
        style={{ backgroundColor: numQuestions === 20 ? '#4CAF50' : 'skyblue' }}
      >
        20 Questions
      </button>
      <button
        onClick={() => setNumQuestions(30)}
        style={{ backgroundColor: numQuestions === 30 ? '#4CAF50' : 'skyblue' }}
      >
        30 Questions
      </button>
      {/* similar for other buttons */}
    </>
  )}
</div>

<div className='operation'>
  
  {operation !== 'multiplication-alert' && (
    
    <>
    <h2>Choose numbers (1-12)</h2>
      {Array.from({ length: 12 }, (_, i) => (
        <button
          key={i}
          style={{
            backgroundColor: selectedButtons.includes(i + 1) ? '#4CAF50' : 'skyblue',
          }}
          onClick={() => {
            if (selectedButtons.includes(i + 1)) {
              setSelectedButtons(selectedButtons.filter((number) => number !== i + 1));
            } else {
              setSelectedButtons([...selectedButtons, i + 1]);
            }
          }}
        >
          {i + 1}
        </button>
      ))}
      <button onClick={deselectAll}>Deselect All</button>
    </>
  )}
</div>








          <div className='operation'>
            <button onClick={generateQuestions} className='startbtn'>Start</button>
          </div>
        </>
      ) : (
        <>
          <div className='operation'>
    
    {(currentIndex < questions.length && !showResults) ? (
      <div>
        <Calculator
          displayValue={`${questions[currentIndex]} = ${userInput}`}
          onButtonClick={handleCalculatorButtonClick}
          onSubmit={submitAnswer}
        />
        <div>
          {/* <button className='btnz'  onClick={(event) => {
submitAnswer();
event.currentTarget.blur();
}}  >Submit Answer</button> */}
        </div>
      </div>
    ) : (
      <div className="correct-answer">Completed!</div>
    )}
  </div>
  <div className='operation'>
    <h2>Results:</h2>
    <div className="correct-answer">Correct Answers: {correctCount}</div>
    <div className="incorrect-answer">Incorrect Answers: {incorrectCount}</div>
    {endTime && (
<div className="time-taken">
  <div className='correct-answer'>
  Time taken: {((endTime - startTime) / 1000).toFixed(2)} seconds
  </div>
  
</div>
)}
{showResults || currentIndex === questions.length ? (
  <>
    <button onClick={restartQuiz} style={{marginTop:'30px'}}>Restart Quiz</button>
    <table className="quiz-results-table">
      <thead>
        <tr>
          <th>Question</th>
          <th>Correct Answer</th>
          <th>Your Answer</th>
        </tr>
      </thead>
      <tbody>
        {userAnswers.map((item, index) => (
          <tr key={index} style={{ backgroundColor: item.userAnswer != item.correctAnswer ? '#F56969' : 'transparent' }}>
            <td data-label="Question">{item.question.replace('*', 'x').replace('/', '÷')}</td>
            <td data-label="Correct Answer">{item.correctAnswer}</td>
            <td data-label="Your Answer">{item.userAnswer}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </>
) : null}
    
  </div>        </>
      )}
    </div>
    </div>
  );



}

export default QuizMain;
