// src/Calculator.js
import React from 'react';
import './QuizMain.css'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

const Calculator = ({ displayValue, onButtonClick,onSubmit }) => {
  const buttons = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '0', value: '0' },
    { label: 'DEL', value: 'backspace' },
    
  ];
const formattedDisplayValue = displayValue.replace('*', 'x').replace('/', '÷');



  // const handleClick = (event) => {
  //    const buttonValue = event.target.value;
  //    onButtonClick(buttonValue);
  //    event.currentTarget.blur();
  //  };

   const handleSubmission = (event) => {
     onSubmit();
     event.currentTarget.blur();
   };



  return (
    <div className="calculator">
      <div className="calculator-display">{formattedDisplayValue}</div>
      <div className="calculator-buttons">
        {buttons.map((button) => (
          <button
            key={button.label}
            onClick={(event) => {onButtonClick(button.value); event.currentTarget.blur();}}
          >
            {button.label}
          </button>
        ))}
       {/* <button value="submit" onClick={handleClick}>
      <KeyboardReturnIcon />
    </button>  */}
         <button onClick={handleSubmission}>
        <KeyboardReturnIcon />
      </button> 
      </div>
    </div>
  );
};

export default Calculator;
