import React from 'react'
import styles from './login.module.css'
import Inputcontrol from '../InputControl/InputControl'
import { Link , useNavigate } from 'react-router-dom'
import { useState } from 'react'
import {signInWithEmailAndPassword,sendPasswordResetEmail} from 'firebase/auth'
import { auth } from '../firebase'
import SelectControl from '../selectcontrol/SelectControl'

const Login = () => {
    const navigate=useNavigate()
    const [values,setvalues]=useState({
        name:'',
        email:'',
        pass:''
    })

    const [errorMsg,seterrorMsg]=useState('')
    const [submitButtonDisabled,setsubmitButtonDisabled]=useState(false)

    const handleSubmission=()=>{
        if(!values.name || !values.email || !values.pass ){
            seterrorMsg('Fill all fields ')
            return;
        }
        seterrorMsg('')
        console.log(values)
        setsubmitButtonDisabled(true)

        signInWithEmailAndPassword(auth,values.email,values.pass)
        .then( async res=>{
            const user=res.user

            if (user.displayName === values.name) {
                setsubmitButtonDisabled(false)
                navigate('/main1')
            } else {
                setsubmitButtonDisabled(false)
                seterrorMsg('Name does not match')
            }
        })
        .catch(err=>{
            setsubmitButtonDisabled(false)
            seterrorMsg(err.message)
        })

    }

    const forgetpass=()=>{
        sendPasswordResetEmail(auth, values.email)
        .then(() => {
            console.log('message sent')
            alert('Password reset Email sent')
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorMessage)
            seterrorMsg(errorMessage)
        });
    }

    return (
        <div className='containers'>
            <div className={styles.innerBox}>
                <h1 className={styles.heading}>Login</h1>
               
                <SelectControl 
                    label='Select School'
                    options={['a', 'b', 'c', 'd', 'e']}
                    onChange={(event) =>
                        setvalues((prev) => ({...prev, name: event.target.value}))
                    }
                />



                <Inputcontrol label='Email' type={'email'} placeholder='Enter Email address' 
                    onChange={(event)=>
                        setvalues((prev)=>({...prev,email:event.target.value}))
                    }
                />
                <Inputcontrol label='Password' type={'password'} placeholder='Enter your password'
                    onChange={(event)=>
                        setvalues((prev)=>({...prev,pass:event.target.value}))
                    }
                />

                <div className={styles.footer}>
                    <b className='forget' style={{cursor:'pointer'}} onClick={forgetpass}>Forget Password</b>
                    <b className={styles.error}>{errorMsg}</b>

                    <button disabled={submitButtonDisabled} onClick={handleSubmission}>Login</button>
                    

                </div>
            </div>
        </div>
    )
}

export default Login
