import React from 'react'
import './Home.css'
import  { useEffect } from 'react';
import { Link } from 'react-router-dom';
const Home = () => {
  useEffect(() => {
    document.documentElement.classList.add('Home');
    return () => {
      document.documentElement.classList.remove('Home');
    };
  }, []);
  return (
    <div className='HomeMainDiv'>
        
     <div className='imagediv'></div>
    <Link to='/login2'> <button className='buttonlogin'>Login</button></Link>
     
    <Link to='/sign1'><button className='buttonsign'>Sign up</button></Link>
    </div>
  )
}

export default Home
