import React from 'react'
import './Signup1.css'
import Navbar from '../Navbar/Navbar'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
const Login2 = () => {
  useEffect(() => {
    document.documentElement.classList.add('login2');
    return () => {
      document.documentElement.classList.remove('login2');
    };
  }, []);

  return (
    <div >
      <Navbar name='LOGIN' link='/login'/>
      <div className='buttonsdiv'>
        <Link to='/sign2'><button className='button'>SCHOOL</button></Link>
        <button className='button'>FAMILY</button>
        <button className='button'>TUTOR</button>
      </div>
    </div>
  )
}

export default Login2
