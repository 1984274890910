import React from 'react'
import { Link } from 'react-router-dom';
import { useEffect } from 'react'
import LogoutIcon from '@mui/icons-material/Logout';
import './Main1.css'
import { getAuth, signOut } from "firebase/auth";
const Main = () => {
    useEffect(() => {
        document.documentElement.classList.add('main1');
        return () => {
          document.documentElement.classList.remove('main1');
        };
      }, []);

      const func=()=>{
        const auth = getAuth();
        signOut(auth).then(() => {
          // Sign-out successful.
        }).catch((error) => {
          // An error happened.
        });
      }

  return (
    <div>

      <div className='navbarz'>
       <Link to='/login' > <LogoutIcon className='logout' onClick={func}></LogoutIcon></Link>
      </div>
      <div className='body'>
      <div className='Background'></div>
      <Link to='/quiz '><button className='button1' >Single Player</button></Link>
      <button className='button2'>Multiplayer</button>

      </div>
    </div>
  )
}

export default Main
