import React from 'react'
import styles from './signup.module.css'
import Inputcontrol from '../InputControl/InputControl'
import SelectControl from '../selectcontrol/SelectControl' 
import { Link , useNavigate } from 'react-router-dom'
import { useState } from 'react'
import {createUserWithEmailAndPassword ,updateProfile} from 'firebase/auth'
import { auth ,db } from '../firebase'
import { doc, setDoc } from 'firebase/firestore'



const Signup = () => {
const navigate=useNavigate()
const [values,setvalues]=useState({
    name:'',
    email:'',
    pass:'',
    teacherEmail:'' ,
    studentName:'',
    class:'',
})

const [errorMsg,seterrorMsg]=useState('')
const [submitButtonDisabled,setsubmitButtonDisabled]=useState(false)


const handleSubmission=()=>{
    if(!values.name || !values.email || !values.pass || !values.teacherEmail || !values.studentName || !values.class){
        seterrorMsg('Fill all feilds ')
        return;
    }
    seterrorMsg('')
    setsubmitButtonDisabled(true)

    createUserWithEmailAndPassword(auth,values.email,values.pass)
    .then( async res=>{
        const user=res.user

        // Save the user's teacher's email in Firestore
        const docRef = doc(db, "quizResults", user.uid);
        await setDoc(docRef, { teacherEmail: values.teacherEmail }, { merge: true });
        await setDoc(docRef, { studentName: values.studentName }, { merge: true })
        await setDoc(docRef, { class: values.class }, { merge: true })

        setsubmitButtonDisabled(false)
        await updateProfile(user,{
            displayName:values.name
        })
       navigate('/main1')
           })
    .catch(err=>{
        setsubmitButtonDisabled(false)
        seterrorMsg(err.message)
       })
    
}



  return (
<div className={styles.container}>
      <div className={styles.innerBox}>
        <h1 className={styles.heading}>Signup</h1>
        <SelectControl 
                    label='Select an option'
                    options={['a', 'b', 'c', 'd', 'e']}
                    onChange={(event) =>
                        setvalues((prev) => ({...prev, name: event.target.value}))
                    }
                />

<Inputcontrol label='Teacher Email' type={'email'} placeholder='Enter teacher email address' 
         onChange={(event)=>
        
            setvalues((prev)=>({...prev,teacherEmail:event.target.value}))
            
            
            }
         
         />
         
    
         <Inputcontrol label='Student Name' type={'text'} placeholder='Enter your name' 
         onChange={(event)=>
        
            setvalues((prev)=>({...prev,studentName:event.target.value}))
            
            
            }
         
         />

<Inputcontrol label='Class' type={'text'} placeholder='Enter your class name' 
         onChange={(event)=>
        
            setvalues((prev)=>({...prev,class:event.target.value}))
            
            
            }
         
         />








         <Inputcontrol label='Email' type={'email'} placeholder='Enter Email address' 
         onChange={(event)=>
        
            setvalues((prev)=>({...prev,email:event.target.value}))
            
            
            }
         
         />
         <Inputcontrol label='Password' type={'password'} placeholder='Enter Password' 
         onChange={(event)=>
        
            setvalues((prev)=>({...prev,pass:event.target.value}))
            
            
            }
         />


     <div className={styles.footer}>
        <b className={styles.error}>{errorMsg}</b>
        <button onClick={handleSubmission} disabled={submitButtonDisabled}>signup</button>
       

     </div>

      </div>
    </div>
  )
}

export default Signup
