import React from 'react'
import './Signup2.css'
import Navbar from '../Navbar/Navbar'
import { useEffect ,useState } from 'react'
import Signup from '../signUp/SignUp'
const Login2 = () => {
  useEffect(() => {
    document.documentElement.classList.add('login3');
    return () => {
      document.documentElement.classList.remove('login3');
    };
  }, []);
const[pre,set]=useState(true)

const func=()=>{
  set(false)
}


  return (

  

    <div>
      <Navbar name='SCHOOL' link='/login2'/>
      
      
      { pre?

      <div className='buttonsdiv'>
        <button className='button' onClick={func}>STUDENT</button>
        <button className='button'>TEACHER</button>
        <button className='button'>WONDE</button>
      </div>
      :
      <div className='supermaindiv'>
      <Signup/>
      </div>
}
    </div>
  
  )
}

export default Login2
