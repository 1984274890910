import React from 'react'
import './Navbar.css'
import { Link } from 'react-router-dom'
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import Cookies from 'js-cookie';  
import { getAuth, signOut } from "firebase/auth";
const Navbar = (props) => {
  
  return (
    <div className='maindivx' >
      <Link to={props.link} style={{ color: 'white',textDecoration:"none" }}><ArrowCircleLeftRoundedIcon></ArrowCircleLeftRoundedIcon></Link>
      <h2>LOGO</h2>
    </div>
  )
}

export default Navbar
