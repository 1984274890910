import React from 'react'
import './Login3.css'
import Navbar from '../Navbar/Navbar'
import { useEffect ,useState } from 'react'
import Login4 from '../login4/Login4'
const Login2 = (props) => {
  useEffect(() => {
    document.documentElement.classList.add('login3');
    return () => {
      document.documentElement.classList.remove('login3');
    };
  }, []);
const[pre,set]=useState(true)

const func=()=>{
  set(false)
}
console.log(props.val)


  return (

  

    <div>
      <Navbar name='SCHOOL' link='/login2'/>
      
      
      { pre?

      <div className='buttonsdiv'>
        <button className='button' onClick={func}>STUDENT</button>
        <button className='button'>TEACHER</button>
        <button className='button'>WONDE</button>
      </div>
      :
   
      <div className='supermaindiv'>
      <Login4/>
      </div>


}
    </div>
  
  )
}

export default Login2
