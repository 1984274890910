import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyB5niZi4lyG1IPA5bsr9iKF4wVO9UJ-IbE",
    authDomain: "auth-18df0.firebaseapp.com",
    projectId: "auth-18df0",
    storageBucket: "auth-18df0.appspot.com",
    messagingSenderId: "687879239439",
    appId: "1:687879239439:web:75f48c6ef922bfb6fbf07a",
    measurementId: "G-GZ82SXQ92X"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export {app, auth, db};
