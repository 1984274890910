import React from 'react'
import { Navigate ,Outlet } from 'react-router-dom'
import Cookies from 'js-cookie';

const Privateroute = () => {
  // Check if user data is stored in cookie
  const islogged = !!Cookies.get('user');

  return islogged?<Outlet/>:<Navigate to='login3'/>
}

export default Privateroute
