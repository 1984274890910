// SelectControl.js
import React from 'react';
import styles from './selectControl.module.css';

const SelectControl = ({ label, options, onChange }) => {
    return (
        <div className={styles.wrapper}>
            {label && <label className={styles.label}>{label}</label>}
            <select className={styles.select} onChange={onChange}>
                <option value="">School Name</option>
                {options.map((option, index) => (
                    <option key={index} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default SelectControl;
