import React from "react";
import { BrowserRouter as Router, Routes,Route } from 'react-router-dom'
import Home from "./home/Home";
import Main from "./Main/Main";
import Login2 from "./Login2/Login2";
import Login3 from './Login3/Login3'
import Login4 from './login4/Login4'
import Signup from "./signUp/SignUp";
import Main1 from './Main1/Main'
import BeforeStart from "./beforestart/BeforeStart";
import QuizMain from "./Quiz/QuizMain";
import Signup1 from "./Signup1/Signup1";
import Signup2 from './Signup2/Signup2'
import { auth } from "./firebase";
import Privateroute from "./Privateroute";
import { useState,useEffect } from "react";
import Cookies from 'js-cookie';  
function App() {

  const [islogged,setislogged]=useState(false)
  const [userName,setUserName]=useState('')
  const [userData, setUserData] = useState(null);
  useEffect(()=>{
    auth.onAuthStateChanged(user=>{
      if(user){
        setUserName(user.displayName)
        setislogged(true)
        Cookies.set('user', JSON.stringify(user), { expires: 7 }); // set cookie
      //   const userCookie = Cookies.get('user'); // get cookie
      // const userData = JSON.parse(userCookie); // parse the JSON string
      // setUserData(userData)
      const userCookie = Cookies.get('user'); // get cookie
        const parsedData = JSON.parse(userCookie); // parse the JSON string
        setUserData(parsedData); // store cookie data in state
        console.log(user)
      
      }
      else {
        setUserName('')
        
        Cookies.remove('user'); // remove cookie
        setUserData(null);
      }
    })
    
  }, []) 
  useEffect(() => {
    // Console log the userData state
  }, [userData]); 
  

  return (
    <div>
      {userData?<Router basename={"/app"}><Routes><Route path='/' element={<QuizMain/>}></Route>
      <Route path='Login' element={<Home/>}></Route>
      <Route path='login4' element={<Login4/>}></Route>
        <Route path='signup' element={<Signup/>}></Route>
        <Route path='login3' element={<Login3 val={userData}/>}></Route>   
        <Route path='login2' element={<Login2/>}></Route>
       
        
        <Route path='sign1' element={<Signup1/>}></Route>
        <Route path='sign2' element={<Signup2/>}></Route>
      <Route element={<Privateroute islogged={islogged}  />}>
      <Route path='main1' element={<Main1/>}></Route>
     {/* <Route path='before' element={<BeforeStart/>}></Route> */}
      <Route path='quiz' element={<QuizMain/>}></Route>
      
        </Route>
      
       </Routes></Router>:
      <Router basename={"/app"}>
        
        <Routes>
        <Route path='login4' element={<Login4/>}></Route>
        <Route path='signup' element={<Signup/>}></Route>
        <Route path='login3' element={<Login3 val={userData}/>}></Route>   
        <Route path='login2' element={<Login2/>}></Route>
       
        
        <Route path='sign1' element={<Signup1/>}></Route>
        <Route path='sign2' element={<Signup2/>}></Route>
        
        <Route path='/' element={<Main/>}></Route> 
        <Route path='Login' element={<Home/>}></Route>
            
      <Route element={<Privateroute islogged={islogged}  />}>
      <Route path='main1' element={<Main1/>}></Route>
     {/* <Route path='before' element={<BeforeStart/>}></Route> */}
      <Route path='quiz' element={<QuizMain/>}></Route>

        </Route>
        </Routes>
      </Router>}
       
  


    </div>
  );
}

export default App;
