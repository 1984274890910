import React, { useState, useEffect } from 'react';
import './Main.css';
import { Link } from 'react-router-dom';
import Close from '@mui/icons-material/Close';

const Main = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    document.documentElement.classList.add('Main');
    return () => {
      document.documentElement.classList.remove('Main');
    };
  }, []);

  return (
    <div className='Maindiv'>
      <div className='navbar1'>
        <p>HELP</p>
        <span>|</span>
        <p><Link style={{ color: 'white',textDecoration:"none" }} to='/login'>LOGIN</Link></p>
      </div>
      <div className='navbar2'>
        <div className='logo'></div>
        <div className='logo'></div>
        <div className='hamburger' onClick={handleToggle}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={`navbaroptions ${isOpen ? 'open' : ''}`}>
          <Close className='close-icon' onClick={handleToggle} />
          <ul>
            <li>SCHOOLS</li>
            <li>FAMILY</li>
            <li>TUTORS</li>
            <li>PURCHASE</li>
            <li>MERCHANDISE</li>
            <li>CASE STUDIES</li>
            <li>EVENTS</li>
           
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Main;
